<template>
  <div class="referral">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>
    <SectionHeading classname="text-center" text="AFFILIATES PROGRAM"  isMargin="mb-3 mt-5"></SectionHeading>


    <div class="steps" >
      <div class="container">

        <div class="row gy-4">
          <div class="item col-12 col-lg-4" v-for="(step,index) in steps" :key="index">
            <div class=step>
<!--              <div class="img" :style="{width: index === 2 ? '4.8125rem' : ''}">-->
              <div class="img">
                <img :src="step.img" alt="" >
              </div>
              <div class="info">
                <div class="title">{{step.title}}</div>
                <div class="content">
                  {{step.content}}
                </div>
              </div>
            </div>
          </div>
<!--          <div class="col-4"></div>-->
<!--          <div class="col-4"></div>-->
        </div>
      </div>

    </div>


    <div class="join-info">
      <div class="container">
        <div class="row gy-4 align-items-end flex flex-lg-row-reverse">

          <div class="col-lg-6" >
            <div class="about-content-wrap">
              Join our Partner Program and earn a 3% bonus based on your referrals' initial investment amounts! You can run it like a boss and start earning money without investing a single penny.
            </div>
            <br/>
            <div class="about-content-wrap">
              For instance, if someone invests in a $200 plan using your referral code, you will get $6 for free. With unlimited referrals, your earning potential is limitless!
            </div>
            <br/>
            <div class="about-content-wrap">
              So, why wait? Sign up now and start making money by sharing your referral link with friends and family.
            </div>
            <ButtonLink text="JOIN US >" style="margin-top: 2.25rem;width: 50%"  link="/profile/dashboard" classname="btn btn-dark"></ButtonLink>
<!--            <button  class="btn btn-dark">JOIN US ></button>-->
          </div><!-- end col-lg-6 -->
          <div class="col-lg-6 mb-5 mb-lg-0">
<!--            <img src="../images/referral-img.png" alt="" class="w-100" >-->
            <img src="@/images/tmp/r-banner2.jpg" alt="" class="w-100" style="border-radius: 10px;" >
          </div><!-- end col-lg-6 -->
        </div><!-- end row -->
      </div><!-- end container -->
    </div>


    <Footer classname="bg-cus on-dark"></Footer>
  </div>
</template>

<script>
import HeaderMain from "@/components/n1/HeaderMain"
import SectionHeading from "@/components/n1/SectionHeading"
import Footer from "@/pages/n1/Footer"


export default {
  name: "Referral",
  components: {
    HeaderMain,
    SectionHeading,
    Footer
  },

  data(){
    return {
      steps: [
        {
          // img: require('@/images/step-img_1.png'),
          img: require('@/images/tmp/r-signup2.png'),
          title: 'SIGN UP',
          content: 'Join our community, sign up an account, get your affiliates link.'
        },
        {
          img: require('@/images/tmp/r-friend2.png'),
          title: 'INVITE USERS',
          content: 'Invite users, sharing your affiliates link via social media.'
        },
        {
          img: require('@/images/tmp/r-money2.png'),
          title: 'EARN COMMISSIONS',
          content: 'New user signs up & You get 3% of their purchase lifetime.'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.bg-cus {
  background-color: #ffe5d0;
}

.steps{
  //margin-top: 2.1875rem;
  .step{
    display: flex;
    align-items: center;
    background: #F9F9F9;
    border-radius: 0.5rem;
    padding: 1.625rem 1rem;
    .img{
      width: 6.875rem;

      img{
        width: 100%;
        height: auto;
      }
    }
    .info{
      margin-left: 1.5rem;
      width: 0;
      flex: 1;
      .title{
        height: 1.375rem;
        font-size: 1.1rem;
        font-weight: bold;
        //font-family: PingFangSC-Medium, PingFang SC;
        //font-weight: 500;
        color: #1C2A46;
        line-height: 21px;
      }
      .content{
        font-size: 0.85rem;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1C2A46;
        line-height: 1.125rem;
        margin-top: 1rem;
      }
    }
  }
}

.join-info{
  margin-top: 1rem;
  margin-bottom: 4.125rem;
  .about-content-wrap{
    //width: 388px;
    //height: 200px;
    font-size: 0.875rem;
    //font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    //color: #8492A6;
    color: #1C2A46;
    line-height: 2rem;
  }
}

@media (min-width: 1200px)  {
  .steps{
    .info{
      .title{
        font-size: 1.2rem;
      }
    }
  }
}

@media (min-width: 992px)  {
  .join-info{
    .about-content-wrap{
      font-size: 1rem;
    }

  }
}
</style>
